/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  "Action": "Действие",
  "Active": "Активный",
  "Add": "Добавить",
  "Add Attendance": "Добавить посещаемость",
  "Add Equipment": "Добавить оборудование",
  "Add Member": "Добавить участника",
  "Add Payment": "Добавить платеж",
  "Add Plan": "Добавить план",
  "Add Product": "Добавить продукт",
  "Added at": "Добавлено",
  "Address": "Адрес",
  "All orders": "Все заказы",
  "Arrival time": "Время прибытия",
  "Arrived at": "Время прибытия",
  "Attendance": "Посещаемость",
  "Attendance already exists": "Посещаемость уже существует",
  "Attendance created successfully": "Посещаемость успешно создана",
  "Attendance deleted successfully": "Посещаемость успешно удалена",
  "Attendance has been created": "Посещаемость создана",
  "Attendance updated successfully": "Посещаемость успешно обновлена",
  "Attendances": "Посещаемость",
  "Cancel": "Отмена",
  "cancelled": "отменен",
  "Cancelled": "Отменено",
  "card": "Карта",
  "Card": "Карта",
  "cash": "Наличные",
  "Cash": "Наличные",
  "Change Camera": "Изменить камеру",
  "Confirm": "Подтвердить",
  "Count": "Количество",
  "Create Equipment": "Создать оборудование",
  "Create Member": "Создать участника",
  "Create Payment": "Создать платеж",
  "Create Plan": "Создать план",
  "Create Product": "Создать продукт",
  "CUSTOMER NAME": "ИМЯ КЛИЕНТА",
  "Daily": "Ежедневно",
  "Date": "Дата",
  "DATE": "ДАТА",
  "Delete": "Удалить",
  "Details": "Подробности",
  "Do you confirm logging out?": "Подтверждаете ли вы выход из системы?",
  "Do you have an account ?": "У вас есть учетная запись?",
  "Do you want to log out?": "Выйти?",
  "Edit": "Редактировать",
  "Email": "Электронная почта",
  "Employees": "Сотрудники",
  "English": "Английский",
  "Equipment": "Оборудование",
  "Equipment added successfully": "Оборудование успешно добавлено",
  "Equipment Attendance": "Оборудование",
  "Equipment created successfully": "Оборудование успешно создано",
  "Equipment deleted successfully": "Оборудование успешно удалено",
  "Equipment Installed At": "Установлено оборудование",
  "Equipment Name": "Название оборудования",
  "Equipment Price": "Цена оборудования",
  "Equipment Quantity": "Количество оборудования",
  "Equipment updated successfully": "Оборудование успешно обновлено",
  "Equipment Weight": "Вес оборудования",
  "Error": "Ошибка",
  "Expense": "Расход",
  "Filter": "Фильтр",
  "First Name": "Имя",
  "Image": "Изображение",
  "In Active": "Неактивный",
  "Installed At": "Установлено в",
  "items": "элементы",
  "Language": "Язык",
  "Last Name": "Фамилия",
  "Leaved at": "Время отправления",
  "Leaving time": "Время ухода",
  "Member": "Участник",
  "Member Address": "Адрес участника",
  "Member Email": "Электронная почта участника",
  "Member First Name": "Имя участника",
  "Member Language": "Язык участника",
  "Member Last Name": "Фамилия участника",
  "Member Password": "Пароль участника",
  "Member Phone": "Телефон участника",
  "Member Status": "Статус участника",
  "Member updated successfully": "Участник успешно обновлен",
  "Members": "Участники",
  "Membership": "Членство",
  "Membership created successfully": "Членство успешно создано",
  "Membership deleted successfully": "Членство успешно удалено",
  "Membership End Date": "Дата окончания членства",
  "Membership updated successfully": "Членство успешно обновлено",
  "Method": "Способ",
  "METHOD": "МЕТОД",
  "Monthly": "Ежемесячно",
  "Name": "Название",
  "NO": "НОМЕР",
  "ORDER ID": "НОМЕР ЗАКАЗА",
  "Original Price": "Исходная цена",
  "Others": "Другие",
  "paid": "оплаченный",
  "Paid": "Оплаченный",
  "Password": "Пароль",
  "Payment": "Платеж",
  "Payment added successfully": "Платеж успешно добавлен",
  "Payment date": "Дата платежа",
  "Payment deleted successfully": "Платеж успешно удален",
  "Payment Method": "Способ оплаты",
  "Payment updated successfully": "Платеж успешно обновлен",
  "Payments": "Платежи",
  "pending": "в ожидании",
  "Pending": "В ожидании",
  "per account": "на аккаунт",
  "Phone": "Телефон",
  "piece": "штука",
  "Piece": "Штука",
  "Plan": "План",
  "Plan added successfully": "План успешно добавлен",
  "Plan Price": "Плановая цена",
  "Plan Tags": "Теги плана",
  "Plan Text": "Текст плана",
  "Plan Title": "Название плана",
  "Plan updated successfully": "План успешно обновлен",
  "Plans": "Планы",
  "Please input your email!": "Пожалуйста, введите адрес электронной почты!",
  "Please input your first name!": "Пожалуйста, введите свое имя!",
  "Please input your password!": "Пожалуйста, введите свой пароль!",
  "Please input your phone!": "Пожалуйста, введите свой телефон!",
  "Price": "Цена",
  "PRICE": "ЦЕНА",
  "Product": "Продукт",
  "Product added successfully": "Продукт успешно добавлен",
  "Product created successfully": "Продукт успешно создан",
  "Product deleted successfully": "Продукт успешно удален",
  "PRODUCT ID": "ИД НОМЕР ПРОДУКТА",
  "Product Name": "Название продукта",
  "PRODUCT NAME": "НАИМЕНОВАНИЕ ТОВАРА",
  "Product Original Price": "Первоначальная цена продукта",
  "Product Price": "Цена продукта",
  "Product Quantity": "Количество продукта",
  "Product Unit": "Единица продукта",
  "Product updated successfully": "Продукт успешно обновлен",
  "Products": "Продукты",
  "Profile": "Профиль",
  "Profit": "Прибыль",
  "QRCode": "QRCode",
  "QRCode Scanner": "Сканер QRCode",
  "Quantity": "Количество",
  "reception": "Регистратор",
  "refunded": "возмещено",
  "Refunded": "Возврат",
  "Role": "Роль",
  "Russian": "Русский",
  "Sale added successfully": "Продажа успешно добавлена",
  "Sale deleted successfully": "Продажа успешно удалена",
  "Save": "Сохранить",
  "Search": "Поиск",
  "Search orders with customer name": "Поиск заказов по имени клиента",
  "Security": "Безопасность",
  "Selected": "Выбрано",
  "Sell": "Продать",
  "Sell Product": "Продать продукт",
  "Settings": "Настройки",
  "Sign In": "Войти",
  "Sign Out": "Выйти",
  "Sign Up": "Зарегистрироваться",
  "Status": "Статус",
  "STATUS": "СТАТУС",
  "Success": "Успех",
  "Total": "Всего",
  "Total Membership": "Общая сумма членства",
  "Total Payments": "Общая сумма платежей",
  "Total Price": "Общая цена",
  "Total Sales": "Общая сумма продаж",
  "Unit": "Единица",
  "Update Attendance": "Обновить посещаемость",
  "Update Equipment": "Обновить оборудование",
  "Update Member": "Обновить участника",
  "Update Password": "Обновить пароль",
  "Update Payment": "Обновить платеж",
  "Update Plan": "Обновить план",
  "Update Product": "Обновить продукт",
  "Upgrade Now": "Обновить сейчас",
  "User created successfully": "Пользователь успешно создан",
  "User deleted successfully": "Пользователь успешно удален",
  "User Id": "Идентификатор пользователя",
  "User updated successfully": "Пользователь успешно обновлен",
  "view": "смотреть",
  "View": "Cмотреть",
  "Weight": "Вес",
  "Yearly": "Ежегодно"
}