/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  "Action": "Vazifalar",
  "Active": "Faol",
  "Add": "Qo'shish",
  "Add Attendance": "Davomat qo'shish",
  "Add Equipment": "Uskunani qoʻshish",
  "Add expence": "Xarajat qo'shish",
  "Add Member": "A'zo qo'shish",
  "Add Payment": "Toʻlovni qoʻshish",
  "Add Payoffice": "Kassa qo'shish",
  "Add Plan": "Reja qo'shish",
  "Add Product": "Mahsulotni qo'shish",
  "Added at": "Qo'shilgan vaqt",
  "Address": "Manzil",
  "All orders": "Barcha buyurtmalar",
  "Amount": "Qiymat",
  "Arrival time": "Kirish vaqti",
  "Arrived at": "Kirish vaqti",
  "Attendance": "Davomat",
  "Attendance already exists": "Davomat allaqachon mavjud",
  "Attendance created successfully": "Davomat muvaffaqiyatli yaratildi",
  "Attendance deleted successfully": "Davomat muvaffaqiyatli oʻchirildi",
  "Attendance has been created": "Davomat yaratildi",
  "Attendance updated successfully": "Davomat muvaffaqiyatli yangilandi",
  "Attendances": "Davomatlar",
  "bill": "komunal",
  "Bill": "Komunal",
  "Cancel": "Bekor qilish",
  "cancelled": "bekor qilingan",
  "Cancelled": "Bekor qilingan",
  "card": "Karta",
  "Card": "Karta",
  "cash": "Naqd pul",
  "Cash": "Naqd pul",
  "Change Camera": "Kamerani o'zgartirish",
  "Coach is not needed": "Murabbiy kerak emas",
  "Confirm": "Tasdiqlash",
  "confirmed": "Qabul qilindi",
  "Count": "Hisoblash",
  "Create Equipment": "Uskuna yaratish",
  "Create Member": "A'zo yaratish",
  "Create Payment": "To'lovni yaratish",
  "Create Plan": "Reja yaratish",
  "Create Product": "Mahsulot yaratish",
  "CUSTOMER NAME": "MIJOZ NOMI",
  "Daily": "Kunlik",
  "Date": "Sana",
  "DATE": "SANA",
  "debt": "qarz",
  "Debt": "Qarz",
  "Delete": "Oʻchirish",
  "Details": "Tafsilotlar",
  "Do you confirm logging out?": "Tizimdan chiqishni tasdiqlaysizmi?",
  "Do you have an account ?": "Sizda hisob qaydnomangiz bormi?",
  "Do you want to log out?": "Tizimdan chiqmoqchimisiz?",
  "Edit": "Tahrirlash",
  "Edit expence": "Xarajatni yangilash",
  "Email": "Elektron pochta",
  "Employees": "Xodimlar",
  "English": "Inglizcha",
  "Equipment": "Uskuna",
  "Equipment added successfully": "Uskuna muvaffaqiyatli qoʻshildi",
  "Equipment Attendance": "Uskunalar Davomati",
  "Equipment created successfully": "Uskuna muvaffaqiyatli yaratildi",
  "Equipment deleted successfully": "Uskuna muvaffaqiyatli o'chirildi",
  "Equipment Installed At": "Uskunaning o'rnatilgan vaqti",
  "Equipment Name": "Uskuna nomi",
  "Equipment Price": "Uskuna narxi",
  "Equipment Quantity": "Uskuna miqdori",
  "Equipment updated successfully": "Uskuna muvaffaqiyatli yangilandi",
  "Equipment Weight": "Uskunaning og'irligi",
  "Error": "Xato",
  "Expence date": "Xarajat sanasi",
  "Expence Type": "Xarajat turi",
  "Expences": "Xarajatlar",
  "Expense": "Xarajat",
  "Expense deleted successfully": "Xarajat muvaffaqiyatli oʻchirildi",
  "Filter": "Filtr",
  "First Name": "Ism",
  "From": "Dan",
  "Gym": "Zal",
  "Image": "Rasm",
  "In Active": "Nofaol",
  "Income": "Kirim",
  "Installed At": "O'rnatilgan vaqti",
  "inventory": "uskuna",
  "items": "elementlar",
  "Language": "Til",
  "Last Name": "Familiya",
  "Leaved at": "Ketish vaqti",
  "Leaving time": "Chetlanish vaqti",
  "Member": "A'zo",
  "Member Address": "A'zo manzili",
  "Member Email": "A'zo elektron pochtasi",
  "Member First Name": "A'zoning ismi",
  "Member Language": "A'zo tili",
  "Member Last Name": "A'zo familiyasi",
  "Member Password": "A'zo paroli",
  "Member Phone": "A'zo telefoni",
  "Member Status": "A'zo maqomi",
  "Member updated successfully": "Aʼzo muvaffaqiyatli yangilandi",
  "Members": "Aʼzolar",
  "Membership": "Aʼzolik",
  "Membership created successfully": "Aʼzolik muvaffaqiyatli yaratildi",
  "Membership deleted successfully": "Aʼzolik muvaffaqiyatli oʻchirildi",
  "Membership End Date": "Aʼzolikning tugash sanasi",
  "Membership updated successfully": "Aʼzolik muvaffaqiyatli yangilandi",
  "Method": "Usul",
  "METHOD": "METOD",
  "Monthly": "Oylik",
  "Name": "Nomi",
  "NO": "RAQAM",
  "No note": "Izoh yo'q",
  "Note": "Izoh",
  "ORDER ID": "Buyurtma ID",
  "Original Price": "Kelish narx",
  "other": "boshqa",
  "Other": "Boshqa",
  "Others": "Boshqalar",
  "paid": "to'langan",
  "Paid": "Toʻlangan",
  "Password": "Parol",
  "Payment": "To'lov",
  "Payment added successfully": "Toʻlov muvaffaqiyatli qoʻshildi",
  "Payment date": "Toʻlov sanasi",
  "Payment deleted successfully": "Toʻlov muvaffaqiyatli oʻchirildi",
  "Payment Method": "Toʻlov usuli",
  "Payment updated successfully": "Toʻlov muvaffaqiyatli yangilandi",
  "Payments": "Toʻlovlar",
  "Payoffice": "Kassa",
  "pending": "kutilmoqda",
  "Pending": "Kutilayotgan",
  "per account": "hisob uchun",
  "Phone": "Telefon",
  "piece": "dona",
  "Piece": "Dona",
  "Plan": "Reja",
  "Plan added successfully": "Reja muvaffaqiyatli qoʻshildi",
  "Plan Price": "Reja narxi",
  "Plan Tags": "Reja teglari",
  "Plan Text": "Reja matni",
  "Plan Title": "Reja nomi",
  "Plan updated successfully": "Reja muvaffaqiyatli yangilandi",
  "Plans": "Rejalar",
  "Please input your email!": "Iltimos, elektron pochtangizni kiriting!",
  "Please input your first name!": "Iltimos, ismingizni kiriting!",
  "Please input your password!": "Parolingizni kiriting!",
  "Please input your phone!": "Telefoningizni kiriting!",
  "Price": "Narx",
  "PRICE": "NARX",
  "product": "mahsulot",
  "Product": "Mahsulot",
  "Product added successfully": "Mahsulot muvaffaqiyatli qoʻshildi",
  "Product created successfully": "Mahsulot muvaffaqiyatli yaratildi",
  "Product deleted successfully": "Mahsulot muvaffaqiyatli o'chirildi",
  "PRODUCT ID": "MAHSULOT ID",
  "Product Name": "Mahsulot nomi",
  "PRODUCT NAME": "MAHSULOT NOMI",
  "Product Original Price": "Mahsulotning asl narxi",
  "Product Price": "Mahsulot narxi",
  "Product Quantity": "Mahsulot miqdori",
  "Product Unit": "Mahsulot birligi",
  "Product updated successfully": "Mahsulot muvaffaqiyatli yangilandi",
  "Products": "Mahsulotlar",
  "Profile": "Profil",
  "Profit": "Foyda",
  "Purpose": "Maqsad",
  "QRCode": "QRCod",
  "QRCode Scanner": "QRCode Skaneri",
  "Quantity": "Miqdor",
  "reception": "Qabulxonachi",
  "Reception": "Qabulxonachi",
  "refunded": "qaytarilgan",
  "Refunded": "Toʻlov qaytarilgan",
  "rent": "ijara",
  "Rent": "Ijara",
  "Role": "Rol",
  "Russian": "Ruscha",
  "salary": "oylik",
  "Salary": "Oylik",
  "Sale added successfully": "Sotish muvaffaqiyatli qoʻshildi",
  "Sale deleted successfully": "Sotish muvaffaqiyatli oʻchirildi",
  "Save": "Saqlash",
  "Search": "Qidirish",
  "Search orders with customer name": "Mijoz nomi bilan buyurtmalarni qidirish",
  "Security": "Xavfsizlik",
  "Selected": "Tanlangan",
  "Sell": "Sotish",
  "Sell Product": "Mahsulotni sotish",
  "Selling price": "Sotish narxi",
  "Settings": "Sozlamalar",
  "Should it be marked as paid?": "To'landi deb belgilansinmi?",
  "Sign In": "Kirish",
  "Sign Out": "Chiqish",
  "Sign Up": "Roʻyxatdan oʻtish",
  "Status": "Holat",
  "STATUS": "HOLAT",
  "Success": "Muvaffaqiyatli",
  "The coach is not attached": "Murabbiy biriktirilmagan",
  "To": "Gacha",
  "To whom": "Kimga",
  "Total": "Jami",
  "Total expence": "Umumiy xarajat",
  "Total Membership": "Jami aʼzolik",
  "Total original Price": "Jami kelish summasi",
  "Total Payments": "Jami toʻlovlar",
  "Total price": "Umumiy narx",
  "Total Price": "Jami narx",
  "Total Sales": "Jami savdo",
  "Total selling price": "Jami sotish summasi",
  "Trainer": "Murabbiy",
  "unconfirmed": "Qabul qilinmadi",
  "Unit": "Birlik",
  "Update Attendance": "Davomatni yangilash",
  "Update Equipment": "Uskunani yangilash",
  "Update Member": "Aʼzoni yangilash",
  "Update Password": "Parolni yangilash",
  "Update Payment": "To'lovni yangilash",
  "Update Plan": "Rejani yangilash",
  "Update Product": "Mahsulotni yangilash",
  "Upgrade Now": "Hozir yangilash",
  "User created successfully": "Foydalanuvchi muvaffaqiyatli yaratildi",
  "User deleted successfully": "Foydalanuvchi muvaffaqiyatli oʻchirildi",
  "User Id": "Foydalanuvchi identifikatori",
  "User updated successfully": "Foydalanuvchi muvaffaqiyatli yangilandi",
  "view": "koʻrish",
  "View": "Koʻrish",
  "Weight": "Og'irligi",
  "Yearly": "Yillik",
  "You want to accept": "Qabul qilishni xohlaysizmi?"
}