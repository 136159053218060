import { lazy } from 'react';
import { BiBookAlt, BiGroup, BiDumbbell, BiCreditCardAlt, BiCreditCard, BiCog, BiHourglass, BiScan, BiDesktop} from 'react-icons/bi';

const Dashboard = lazy(() => import('../views/Dashboard'));
const Equipment = lazy(() => import('../views/Equipment'));
const EquipmentAttendanceDetails = lazy(() => import('../views/EquipmentDetails'));
const Attendances = lazy(() => import('../views/Attendances'));
const Products = lazy(() => import('../views/Products'));
const Members = lazy(() => import('../views/Members'));
const Payments = lazy(() => import('../views/Payments'));
const SignUp = lazy(() => import('../views/Auth/SignUp'));
const SignIn = lazy(() => import('../views/Auth/SignIn'));
const Settings = lazy(() => import('../views/Settings'));
const Profile = lazy(() => import('../views/Profile'));
const Expences = lazy(() => import('../views/Expences'));
const QRCode = lazy(() => import('../views/QRCode'));

export const MAIN_ROUTES = [
  {
    allowedRoles: ['admin'],
    path: '/dashboard',
    element: Dashboard,
    icon: <BiDesktop size={24} />,
    title: 'Dashboard',
    hidden: false
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/',
    element: Equipment,
    icon: <BiDumbbell size={24} />,
    title: 'Equipment',
    hidden: false
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/equipment/attendances/:id',
    element: EquipmentAttendanceDetails,
    icon: <BiDumbbell size={24} />,
    title: 'Equipment',
    hidden: true
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/products',
    element: Products,
    icon: <BiBookAlt size={24} />,
    title: 'Products',
    hidden: false
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/members',
    element: Members,
    icon: <BiGroup size={24} />,
    title: 'Members',
    hidden: false
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/payments',
    element: Payments,
    icon: <BiCreditCardAlt size={24} />,
    title: 'Payments',
    hidden: false
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/expences',
    element: Expences,
    icon: <BiCreditCard size={24} />,
    title: 'Expences',
    hidden: false
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/attendances',
    element: Attendances,
    icon: <BiHourglass size={24} />,
    title: 'Attendances',
    hidden: false
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/qrcode-scanner',
    element: QRCode,
    icon: <BiScan size={24} />,
    title: 'QRCode Scanner',
    hidden: false
  },
  {
    allowedRoles: ['admin', 'reception'],
    path: '/settings',
    element: Settings,
    icon: <BiCog size={24} />,
    title: 'Settings',
    hidden: false
  },
  {
    allowedRoles: ['admin'],
    path: '/profile',
    element: Profile,
    icon: <BiCog size={24} />,
    title: 'Profile',
    hidden: true
  }
];

export const AUTH_ROUTES = [
  {
    path: '/sign-in',
    element: SignIn
  },
  {
    path: '/secret',
    element: SignUp
  },
  {
    path: '/qrcode-scanner',
    element: QRCode,
    icon: <BiScan size={24} />,
    title: 'QRCode Scanner',
    hidden: false
  },
  {
    path: '*',
    element: SignIn
  }
];
