/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  "Action": "Action",
  "Active": "Active",
  "Add": "Add",
  "Add Attendance": "Add Attendance",
  "Add Equipment": "Add Equipment",
  "Add Member": "Add Member",
  "Add Payment": "Add Payment",
  "Add Plan": "Add Plan",
  "Add Product": "Add Product",
  "Added at": "Added at",
  "Address": "Address",
  "All orders": "All orders",
  "Arrival time": "Arrival time",
  "Arrived at": "Arrived at",
  "Attendance": "Attendance",
  "Attendance already exists": "Attendance already exists",
  "Attendance created successfully": "Attendance created successfully",
  "Attendance deleted successfully": "Attendance deleted successfully",
  "Attendance has been created": "Attendance has been created",
  "Attendance updated successfully": "Attendance updated successfully",
  "Attendances": "Attendances",
  "Cancel": "Cancel",
  "cancelled": "cancelled",
  "Cancelled": "Cancelled",
  "card": "Card",
  "Card": "Card",
  "cash": "Cash",
  "Cash": "Cash",
  "Change Camera": "Change Camera",
  "Confirm": "Confirm",
  "Count": "Count",
  "Create Equipment": "Create Equipment",
  "Create Member": "Create Member",
  "Create Payment": "Create Payment",
  "Create Plan": "Create Plan",
  "Create Product": "Create Product",
  "CUSTOMER NAME": "CUSTOMER NAME",
  "Daily": "Daily",
  "Date": "Date",
  "DATE": "DATE",
  "Delete": "Delete",
  "Details": "Details",
  "Do you confirm logging out?": "Do you confirm logging out?",
  "Do you have an account ?": "Do you have an account ?",
  "Do you want to log out?": "Do you want to log out?",
  "Edit": "Edit",
  "Email": "Email",
  "Employees": "Employees",
  "English": "English",
  "Equipment": "Equipment",
  "Equipment added successfully": "Equipment added successfully",
  "Equipment Attendance": "Equipment Attendance",
  "Equipment created successfully": "Equipment created successfully",
  "Equipment deleted successfully": "Equipment deleted successfully",
  "Equipment Installed At": "Equipment Installed At",
  "Equipment Name": "Equipment Name",
  "Equipment Price": "Equipment Price",
  "Equipment Quantity": "Equipment Quantity",
  "Equipment updated successfully": "Equipment updated successfully",
  "Equipment Weight": "Equipment Weight",
  "Error": "Error",
  "Expense": "Expense",
  "Filter": "Filter",
  "First Name": "First Name",
  "Image": "Image",
  "In Active": "In Active",
  "Installed At": "Installed At",
  "items": "items",
  "Language": "Language",
  "Last Name": "Last Name",
  "Leaved at": "Leaved at",
  "Leaving time": "Leaving time",
  "Member": "Member",
  "Member Address": "Member Address",
  "Member Email": "Member Email",
  "Member First Name": "Member First Name",
  "Member Language": "Member Language",
  "Member Last Name": "Member Last Name",
  "Member Password": "Member Password",
  "Member Phone": "Member Phone",
  "Member Status": "Member Status",
  "Member updated successfully": "Member updated successfully",
  "Members": "Members",
  "Membership": "Membership",
  "Membership created successfully": "Membership created successfully",
  "Membership deleted successfully": "Membership deleted successfully",
  "Membership End Date": "Membership End Date",
  "Membership updated successfully": "Membership updated successfully",
  "Method": "Method",
  "METHOD": "METHOD",
  "Monthly": "Monthly",
  "Name": "Name",
  "NO": "NO",
  "ORDER ID": "ORDER ID",
  "Original Price": "Original Price",
  "Others": "Others",
  "paid": "paid",
  "Paid": "Paid",
  "Password": "Password",
  "Payment": "Payment",
  "Payment added successfully": "Payment added successfully",
  "Payment date": "Payment date",
  "Payment deleted successfully": "Payment deleted successfully",
  "Payment Method": "Payment Method",
  "Payment updated successfully": "Payment updated successfully",
  "Payments": "Payments",
  "pending": "pending",
  "Pending": "Pending",
  "per account": "per account",
  "Phone": "Phone",
  "piece": "piece",
  "Piece": "Piece",
  "Plan": "Plan",
  "Plan added successfully": "Plan added successfully",
  "Plan Price": "Plan Price",
  "Plan Tags": "Plan Tags",
  "Plan Text": "Plan Text",
  "Plan Title": "Plan Title",
  "Plan updated successfully": "Plan updated successfully",
  "Plans": "Plans",
  "Please input your email!": "Please input your email!",
  "Please input your first name!": "Please input your first name!",
  "Please input your password!": "Please input your password!",
  "Please input your phone!": "Please input your phone!",
  "Price": "Price",
  "PRICE": "PRICE",
  "Product": "Product",
  "Product added successfully": "Product added successfully",
  "Product created successfully": "Product created successfully",
  "Product deleted successfully": "Product deleted successfully",
  "PRODUCT ID": "PRODUCT ID",
  "Product Name": "Product Name",
  "PRODUCT NAME": "PRODUCT NAME",
  "Product Original Price": "Product Original Price",
  "Product Price": "Product Price",
  "Product Quantity": "Product Quantity",
  "Product Unit": "Product Unit",
  "Product updated successfully": "Product updated successfully",
  "Products": "Products",
  "Profile": "Profile",
  "Profit": "Profit",
  "QRCode": "QRCode",
  "QRCode Scanner": "QRCode Scanner",
  "Quantity": "Quantity",
  "reception": "Staff",
  "refunded": "refunded",
  "Refunded": "Refunded",
  "Role": "Role",
  "Russian": "Russian",
  "Sale added successfully": "Sale added successfully",
  "Sale deleted successfully": "Sale deleted successfully",
  "Save": "Save",
  "Search": "Search",
  "Search orders with customer name": "Search orders with customer name",
  "Security": "Security",
  "Selected": "Selected",
  "Sell": "Sell",
  "Sell Product": "Sell Product",
  "Settings": "Settings",
  "Sign In": "Sign In",
  "Sign Out": "Sign Out",
  "Sign Up": "Sign Up",
  "Status": "Status",
  "STATUS": "STATUS",
  "Success": "Success",
  "Total": "Total",
  "Total Membership": "Total Membership",
  "Total Payments": "Total Payments",
  "Total Price": "Total Price",
  "Total Sales": "Total Sales",
  "Unit": "Unit",
  "Update Attendance": "Update Attendance",
  "Update Equipment": "Update Equipment",
  "Update Member": "Update Member",
  "Update Password": "Update Password",
  "Update Payment": "Update Payment",
  "Update Plan": "Update Plan",
  "Update Product": "Update Product",
  "Upgrade Now": "Upgrade Now",
  "User created successfully": "User created successfully",
  "User deleted successfully": "User deleted successfully",
  "User Id": "User Id",
  "User updated successfully": "User updated successfully",
  "Weight": "Weight",
  "Yearly": "Yearly"
}